import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

import { LANGUAGE_PT_BR, LANGUAGE_EN } from 'interact/utils/constants';

i18n
    // i18next-http-backend
    // carrega traduções do servidor
    // https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detecta linguagem do usuário no browser
    // https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // passa a instância de i18n para react-i18next.
    .use(initReactI18next)
    // inicializa i18next
    // documentação das opções: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        supportedLngs: [LANGUAGE_PT_BR, LANGUAGE_EN],
        fallbackLng: code => {
            if (typeof code === 'string' && code.substring(0, 2) === LANGUAGE_EN) {
                return LANGUAGE_EN;
            }
            return LANGUAGE_PT_BR;
        },
        interpolation: {
            escapeValue: false, // react já faz escape por default
            format: (value, format) => {
                switch (format) {
                    case 'capitalize':
                        return value.charAt(0).toUpperCase() + value.slice(1);
                    case 'uppercase':
                        return value.toUpperCase();
                    default:
                        return value;
                }
            },
        },
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
        load: 'currentOnly',
    });

export default i18n;
