import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';

import '@fontsource/roboto';

import IndeterminateLinearProgress from 'interact/components/indeterminate-linear-progress';

import './index.css';

import reportWebVitals from './reportWebVitals';

import './i18n';

const App = lazy(() => import('./interact/app'));

// a aplicação é carregada em uma tag Suspense para o caso das traduções ainda não estarem carregadas
ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<IndeterminateLinearProgress />}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.info))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
